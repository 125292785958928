import { Status } from '../types/common'

export class SystemStatus {
  static statusByIndex = [Status.NOT_STARTED, Status.IN_PROGRESS, Status.COMPLETED, Status.REJECTED] as const

  static indexByStatus = {
    [Status.NOT_STARTED]: 0,
    [Status.IN_PROGRESS]: 1,
    [Status.COMPLETED]: 2,
    [Status.REJECTED]: 3,
  } as const

  static notStarted = SystemStatus.statusByIndex[0]
  static inProgress = SystemStatus.statusByIndex[1]
  static completed = SystemStatus.statusByIndex[2]
  static rejected = SystemStatus.statusByIndex[3]

  static defaultStatusMap: StatusMap = {
    '0': { name: SystemStatus.notStarted, systemStatusId: '0', subStatuses: [] },
    '1': { name: SystemStatus.inProgress, systemStatusId: '1', subStatuses: [] },
    '2': { name: SystemStatus.completed, systemStatusId: '2', subStatuses: [] },
    '3': { name: SystemStatus.rejected, systemStatusId: '3', subStatuses: [] },
  }
}

export type StatusData = {
  name: string
  systemStatusId: string
  subStatuses: SubStatusData[]
}

export type SubStatusData = {
  id: string
  name: string
  order: number
  isVisibleInOptions: boolean
}

export type StatusMap = {
  '0': StatusData
  '1': StatusData
  '2': StatusData
  '3': StatusData
}

export type SystemStatusType = Status
