import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query'
import { api } from '../../api'
import { TimeZoneType } from '../../constants/timezones'
import { TaskResponse } from '../../tasks/api/task-response'
import { Status } from '../../types/common'
import { ProjectPermissionRecord } from './project-permission-response'
import { ProjectResponse } from './project-response'
import { ProjectStateType, ProjectType } from '../types'
import { makeProjectApiEndpoints } from './endpoints'
import { ProjectViewModel, makeProjectViewModel } from './project'
import { TaskViewModel, makeTaskViewModel } from '../../tasks/api/task'
import { makeUrlSearchParams } from '../../utils/url'
import { NotificationChannel, NotificationType } from '../../notifications/notifications'
import { TreeGridColors } from '../../treegrid/types'

const apiEndpoints = makeProjectApiEndpoints()

export const projectApi = api.injectEndpoints({
  endpoints: (builder) => {
    return {
      getProjectById: builder.query<ProjectViewModel, string>({
        query: apiEndpoints.projectItem,
        transformResponse: ({ project }: { project: ProjectResponse }) => makeProjectViewModel(project),
        providesTags: (result) => [{ type: 'Project', id: result?.id }],
      }),
      getProjects: builder.query<ProjectViewModel[], ProjectsQueryParams | void>({
        query: (params) => {
          return {
            method: 'GET',
            url: apiEndpoints.projects(),
            params: params ? makeUrlSearchParams(params) : undefined,
          }
        },
        transformResponse: (response: { projects: ProjectResponse[] }) => {
          return response.projects.map(makeProjectViewModel)
        },
        providesTags: (result = []) => ['Project', ...result.map(({ id }) => ({ type: 'Project', id } as const))],
      }),
      getProjectPermissions: builder.query<ProjectPermissionRecord[], string>({
        query: apiEndpoints.projectPermissions,
        transformResponse: (response: ProjectPermissionRecord[]) => response,
        providesTags: (result, error, id) => ['ProjectPermission', { type: 'ProjectPermission', id }],
      }),
      createUserProject: builder.mutation<ProjectViewModel, { userId: string } & NewProjectData>({
        query: ({ userId, ...projectData }) => ({
          url: apiEndpoints.userProjects(userId),
          method: 'POST',
          body: projectData,
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: ['Project'],
      }),
      createOrgProject: builder.mutation<ProjectViewModel, { orgId: string } & NewProjectData>({
        query: ({ orgId, ...projectData }) => ({
          url: apiEndpoints.orgProjects(orgId),
          method: 'POST',
          body: projectData,
        }),
        transformResponse: makeProjectViewModel,
        transformErrorResponse: (error) => transformError(error, 'Failed to create a new project'),
        invalidatesTags: ['Project'],
      }),
      copyProject: builder.mutation<ProjectViewModel, CopyProjectData>({
        query: (projectData) => ({
          url: apiEndpoints.copyProject(),
          method: 'POST',
          body: projectData,
        }),
        transformResponse: makeProjectViewModel,
        transformErrorResponse: (error) => transformError(error, 'Failed to create a new project'),
        invalidatesTags: ['Project'],
      }),
      updateProjectInfo: builder.mutation<ProjectViewModel, { projectId: string } & ProjectInfoUpdateData>({
        query: ({ projectId, ...projectData }) => ({
          url: apiEndpoints.projectItem(projectId),
          method: 'PUT',
          body: projectData,
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      updateProjectNumber: builder.mutation<
        { project: ProjectViewModel; tasks: TaskViewModel[] },
        { projectId: string } & ProjectNumberUpdateData
      >({
        query: ({ projectId, ...projectData }) => ({
          url: apiEndpoints.projectNumber(projectId),
          method: 'PUT',
          body: projectData,
        }),
        transformResponse: (response: { project: ProjectResponse; tasks: TaskResponse[] }) => {
          return {
            project: makeProjectViewModel(response.project),
            tasks: response.tasks.map((task) => makeTaskViewModel(task)),
          }
        },
        invalidatesTags: (result) => ['Project', 'Task', { type: 'Project', id: result?.project.id }],
      }),
      updateProjectPlan: builder.mutation<ProjectViewModel, { projectId: string } & ProjectPlanUpdateData>({
        query: ({ projectId, ...projectData }) => ({
          url: apiEndpoints.projectPlan(projectId),
          method: 'PUT',
          body: projectData,
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      updateProjectStatus: builder.mutation<
        { project: ProjectViewModel; tasks: TaskViewModel[] },
        { projectId: string } & ProjectStatusUpdateData
      >({
        query: ({ projectId, ...projectData }) => ({
          url: apiEndpoints.projectStatus(projectId),
          method: 'PUT',
          body: projectData,
        }),
        transformResponse: (response: { project: ProjectResponse; tasks: TaskResponse[] }) => {
          return {
            project: makeProjectViewModel(response.project),
            tasks: response.tasks.map((task) => makeTaskViewModel(task)),
          }
        },
        invalidatesTags: (result) => ['Project', 'Task', { type: 'Project', id: result?.project.id }],
      }),
      addProjectManagers: builder.mutation<ProjectViewModel, { projectId: string; managerIds: string[] }>({
        query: ({ projectId, managerIds }) => ({
          url: apiEndpoints.projectManagers(projectId),
          method: 'POST',
          body: { managerIds },
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      removeProjectManagers: builder.mutation<ProjectViewModel, { projectId: string; managerIds: string[] }>({
        query: ({ projectId, managerIds }) => ({
          url: apiEndpoints.projectManagers(projectId),
          method: 'DELETE',
          body: { managerIds },
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      addProjectParticipants: builder.mutation<ProjectViewModel, { projectId: string; participantIds: string[] }>({
        query: ({ projectId, participantIds }) => ({
          url: apiEndpoints.projectParticipants(projectId),
          method: 'POST',
          body: { participantIds },
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      removeProjectParticipants: builder.mutation<ProjectViewModel, { projectId: string; participantIds: string[] }>({
        query: ({ projectId, participantIds }) => ({
          url: apiEndpoints.projectParticipants(projectId),
          method: 'DELETE',
          body: { participantIds },
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      addProjectCustomers: builder.mutation<
        { project: ProjectViewModel; tasks: TaskViewModel[] },
        { projectId: string; customerIds: string[] }
      >({
        query: ({ projectId, customerIds }) => ({
          url: apiEndpoints.projectCustomers(projectId),
          method: 'POST',
          body: { customerIds },
        }),
        transformResponse: (response: { project: ProjectResponse; tasks: TaskResponse[] }) => {
          return {
            project: makeProjectViewModel(response.project),
            tasks: response.tasks.map((task) => makeTaskViewModel(task)),
          }
        },
        invalidatesTags: (result) => ['Project', 'Task', { type: 'Project', id: result?.project.id }],
      }),
      removeProjectCustomers: builder.mutation<
        { project: ProjectViewModel; tasks: TaskViewModel[] },
        { projectId: string; customerIds: string[] }
      >({
        query: ({ projectId, customerIds }) => ({
          url: apiEndpoints.projectCustomers(projectId),
          method: 'DELETE',
          body: { customerIds },
        }),
        transformResponse: (response: { project: ProjectResponse; tasks: TaskResponse[] }) => {
          return {
            project: makeProjectViewModel(response.project),
            tasks: response.tasks.map((task) => makeTaskViewModel(task)),
          }
        },
        invalidatesTags: (result) => ['Project', 'Task', { type: 'Project', id: result?.project.id }],
      }),
      addProjectSuppliers: builder.mutation<ProjectViewModel, { projectId: string; supplierIds: string[] }>({
        query: ({ projectId, supplierIds }) => ({
          url: apiEndpoints.projectSuppliers(projectId),
          method: 'POST',
          body: { supplierIds },
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      removeProjectSuppliers: builder.mutation<ProjectViewModel, { projectId: string; supplierIds: string[] }>({
        query: ({ projectId, supplierIds }) => ({
          url: apiEndpoints.projectSuppliers(projectId),
          method: 'DELETE',
          body: { supplierIds },
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      addProjectWorkspaces: builder.mutation<ProjectViewModel, { projectId: string; workspaceIds: string[] }>({
        query: ({ projectId, workspaceIds }) => ({
          url: apiEndpoints.projectWorkspaces(projectId),
          method: 'POST',
          body: { workspaceIds },
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      removeProjectWorkspaces: builder.mutation<ProjectViewModel, { projectId: string; workspaceIds: string[] }>({
        query: ({ projectId, workspaceIds }) => ({
          url: apiEndpoints.projectWorkspaces(projectId),
          method: 'DELETE',
          body: { workspaceIds },
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      saveBasePlan: builder.mutation<{ project: ProjectViewModel; tasks: TaskViewModel[] }, string>({
        query: (projectId) => ({
          url: apiEndpoints.projectBasePlan(projectId),
          method: 'PUT',
        }),
        transformResponse: (response: { project: ProjectResponse; tasks: TaskResponse[] }) => {
          return {
            project: makeProjectViewModel(response.project),
            tasks: response.tasks.map((task) => makeTaskViewModel(task)),
          }
        },
        invalidatesTags: (result) => ['Project', 'Task', { type: 'Project', id: result?.project.id }],
      }),
      updateTimeComponent: builder.mutation<ProjectViewModel, { projectId: string } & ProjectTimeComponentUpdateData>({
        query: ({ projectId, ...updateData }) => ({
          url: apiEndpoints.projectTimeComponent(projectId),
          method: 'PUT',
          body: updateData,
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      updateProjectStatusDescription: builder.mutation<
        ProjectViewModel,
        { projectId: string } & StatusDescriptionUpdateData
      >({
        query: ({ projectId, ...updateData }) => ({
          url: apiEndpoints.projectStatusDescription(projectId),
          method: 'PUT',
          body: updateData,
        }),
        transformResponse: makeProjectViewModel,
        invalidatesTags: (result) => ['Project', { type: 'Project', id: result?.id }],
      }),
      updateProjectPermissions: builder.mutation<
        ProjectPermissionRecord[],
        { projectId: string } & ProjectPermissionUpdateData
      >({
        query: ({ projectId, membershipIds, abilitiesToAdd = [], abilitiesToRemove = [] }) => ({
          url: apiEndpoints.projectPermissions(projectId),
          method: 'PUT',
          body: { membershipIds, abilitiesToAdd, abilitiesToRemove },
        }),
        transformResponse: (response: ProjectPermissionRecord[]) => response,
        invalidatesTags: (result, error, { projectId }) => [{ type: 'ProjectPermission', id: projectId }],
      }),
      archiveProject: builder.mutation<{ project: ProjectViewModel; tasks: TaskViewModel[] }, string>({
        query: (projectId) => ({
          url: apiEndpoints.archiveProject(projectId),
          method: 'PATCH',
        }),
        transformResponse: (response: { project: ProjectResponse; tasks: TaskResponse[] }) => {
          return {
            project: makeProjectViewModel(response.project),
            tasks: response.tasks.map((task) => makeTaskViewModel(task)),
          }
        },
        invalidatesTags: (result) => ['Project', 'Task', { type: 'Project', id: result?.project.id }],
      }),
      unarchiveProject: builder.mutation<{ project: ProjectViewModel; tasks: TaskViewModel[] }, string>({
        query: (projectId) => ({
          url: apiEndpoints.unarchiveProject(projectId),
          method: 'PATCH',
        }),
        transformResponse: (response: { project: ProjectResponse; tasks: TaskResponse[] }) => {
          return {
            project: makeProjectViewModel(response.project),
            tasks: response.tasks.map((task) => makeTaskViewModel(task)),
          }
        },
        invalidatesTags: (result) => ['Project', 'Task', { type: 'Project', id: result?.project.id }],
      }),
      deleteProject: builder.mutation<boolean, string>({
        query: (projectId) => ({
          url: apiEndpoints.projectItem(projectId),
          method: 'DELETE',
        }),
        transformResponse: () => true,
        onQueryStarted: async (projectId, { dispatch, queryFulfilled }) => {
          await queryFulfilled
          setTimeout(() => {
            dispatch(
              projectApi.util.invalidateTags([
                'Project',
                'Task',
                'CalendarEvent',
                'Todo',
                { type: 'Board', id: projectId },
                { type: 'Project', id: projectId },
              ])
            )
          }, 1000)
        },
      }),
      updateProjectNotificationSettings: builder.mutation<
        ProjectViewModel,
        { projectId: string } & ProjectNotificationSettingsUpdateData
      >({
        query: ({ projectId, ...updateData }) => ({
          url: apiEndpoints.projectNotificationSettings(projectId),
          method: 'PUT',
          body: updateData,
        }),
        invalidatesTags: ['Project'],
      }),
    }
  },
})

export const {
  useGetProjectByIdQuery,
  useGetProjectsQuery,
  useGetProjectPermissionsQuery,
  useCreateUserProjectMutation,
  useCreateOrgProjectMutation,
  useUpdateProjectInfoMutation,
  useUpdateProjectNumberMutation,
  useUpdateProjectPlanMutation,
  useUpdateProjectStatusMutation,
  useAddProjectManagersMutation,
  useRemoveProjectManagersMutation,
  useAddProjectParticipantsMutation,
  useRemoveProjectParticipantsMutation,
  useAddProjectCustomersMutation,
  useRemoveProjectCustomersMutation,
  useAddProjectSuppliersMutation,
  useRemoveProjectSuppliersMutation,
  useAddProjectWorkspacesMutation,
  useRemoveProjectWorkspacesMutation,
  useSaveBasePlanMutation,
  useUpdateTimeComponentMutation,
  useUpdateProjectStatusDescriptionMutation,
  useUpdateProjectPermissionsMutation,
  useArchiveProjectMutation,
  useUnarchiveProjectMutation,
  useDeleteProjectMutation,
  useUpdateProjectNotificationSettingsMutation,
  useCopyProjectMutation,
} = projectApi

type ProjectsBaseQuery = { status?: Status | Status[]; archived?: 'true' | 'false'; type?: ProjectType; links?: 'true' }
type OrgProjectsQueryParams = { organisation: string }
type CustomerProjectsQueryParams = { customer: string }
type MemberProjectsQueryParams = { member: string }
type SupplierProjectsQueryParams = { supplier: string }
type WorkspaceProjectsQueryParams = { workspace: string }
// Expected Projects Query
export type ProjectsQueryParams = ProjectsBaseQuery &
  (
    | {}
    | OrgProjectsQueryParams
    | CustomerProjectsQueryParams
    | MemberProjectsQueryParams
    | SupplierProjectsQueryParams
    | WorkspaceProjectsQueryParams
  )

export type NewProjectData = {
  title: string
  type?: ProjectType
  description?: string
  plannedStartDate?: string
  plannedEndDate?: string
  customers?: string[]
  suppliers?: string[]
  workspaces?: string[]
  managers?: string[]
}

export type CopyProjectData = {
  title: string
  copyFromProjectId: string
  type?: ProjectType
  description?: string
  plannedStartDate?: string
  plannedEndDate?: string
  customers?: string[]
  suppliers?: string[]
  workspaces?: string[]
  managers?: string[]
}

export type ProjectInfoUpdateData = {
  title?: string
  description?: string
  timeZone?: TimeZoneType
  weekendDays?: DayOfWeek[]
  firstDayOfWeek?: DayOfWeek
  ganttBarColor?: TreeGridColors
}

export type ProjectNumberUpdateData = {
  customProjectNumber: string
}

export type ProjectPlanUpdateData = {
  plannedStartDate?: string
  plannedEndDate?: string
  lockPlannedStartDate?: boolean
  lockPlannedEndDate?: boolean
}

export type ProjectStatusUpdateData = {
  actualStartDate?: string
  actualEndDate?: string
  status?: Status
  subStatusId?: string | null
  updateProjectTasks?: boolean
  projectTypeId?: string | null
}

export type ProjectTimeComponentUpdateData = {
  enableTimeComponent: boolean
}

export type StatusDescriptionUpdateData = {
  state?: ProjectStateType
  statusDescription?: string | null
}

export type ProjectPermissionUpdateData = {
  membershipIds: string[]
  abilitiesToAdd?: string[]
  abilitiesToRemove?: string[]
}

function transformError(error: FetchBaseQueryError, defaultMessage = 'Unknown action error') {
  if ('data' in error) {
    try {
      // @ts-expect-error
      return JSON.parse(error.data)
    } catch (error) {
      return { message: defaultMessage }
    }
  }
}

type NotificationUpdateAction = { type: NotificationType; channel: NotificationChannel }
export type ProjectNotificationSettingsUpdateData = {
  enable: NotificationUpdateAction[]
  disable: NotificationUpdateAction[]
}
