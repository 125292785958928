import { TodoColumnName } from './types'
import { makeColumns } from './columns'
import { TreeGridColumn, Option } from '../types'
import { TreeGridTranslations } from '../hooks/use-translations'
import { makeColorKeysEnum } from '../utils'

export const makeLayout = (props: MakeLayoutProps) => {
  const Cfg = makeCfg(props)
  const Cols = makeColumns(props)
  const Head = makeHead(props)
  const Header = makeHeader({ columns: Cols, ...props })
  const Lang = makeLang(props)
  const Panel = makePanel(props)
  const Toolbar = makeToolbar(props)
  const Actions = makeActions()
  const Solid = [{ id: 'NoData', Html: props.translations.noData }]

  return {
    Cfg,
    Cols,
    Head,
    Header,
    Lang,
    Toolbar,
    Actions,
    Solid,
    Panel,
  }
}

const makeCfg = ({ id }: { id: string }) => {
  /** @link link for documentation: https://www.treegrid.com/Doc/SearchAttr.html */
  return {
    id,
    SuppressCfg: process.env.NODE_ENV === 'development' ? 1 : 0,
    Style: 'white', // Base treegrid style
    GanttStyle: 'Material', // Base Gantt style
    Selecting: 0, // disables selecting of rows
    Dragging: 0, // disables dragging of rows
    ScrollLeft: 0, // sets initial horizontal scroll of middle (variable) columns, in pixels.
    PrintVisible: 1,
    PrintExpanded: 1,
    PrintPageOrientation: 1,
    PrintPaddingHeight: 20,
    PDFFitPage: 1,
    Code: process.env.REACT_APP_TREEGRID_CODE,
    Sort: 'completed,dueDate,-createdAt',
    SearchAction: 'Filter',
    WordWrap: 0,
  }
}

const makeHead = ({ dateFormat, gridInfo, isFilterRowVisible, translations }: MakeHeadProps) => {
  const pageHeaderRow = {
    id: 'headerRow',
    Name: translations.pageHeaderRowLabel,
    Space: -1,
    Visible: 0,
    PanelVisible: 0,
    CanExport: 0,
    CanPrint: 2,
    Cells: `Logo,GridInfo,PrintDate`,
    LogoRelWidth: 1,
    LogoAligh: 'Left',
    LogoVAligh: 'middle',
    LogoType: 'Html',
    Logo: `<img style="height: 40px;" src="/favicon.png"  alt="company logo"/>`,
    PrintDate: new Date(),
    PrintDateType: 'Date',
    PrintDateFormat: dateFormat,
    PrintDateCanEdit: 0,
    GridInfo: `<span class="largeText">${gridInfo}<span>`,
    GridInfoType: 'Html',
    GridInfoRelWidth: 2,
  }
  const filtersRow = {
    CanExport: 0,
    CanPrint: 0,
    id: 'filterRow',
    Kind: 'Filter',
    ganttBarColorRange: 1,
    ganttBarColorFilterEnumKeys: 1,
    ganttBarColorEnumKeys: makeColorKeysEnum(translations),
    statusFilterOff: translations.statusFilterOffLabel,
    responsibleFilterOff: translations.responsibleFilterOffLabel,
    participantsFilterOff: translations.participantsFilterOffLabel,
    suppliersFilterOff: translations.suppliersFilterOffLabel,
    workspacesFilterOff: translations.workspacesFilterOffLabel,
    stateFilterOff: translations.stateFilterOffLabel,
    customersFilterOff: translations.customersFilterOffLabel,
    Visible: isFilterRowVisible ? 1 : 0,
  }

  return [pageHeaderRow, filtersRow]
}

const makeHeader = ({ columns, translations }: MakeHeaderProps) => {
  const Header: Record<string, string | number> = {
    Name: translations.headerRowLabel,
    dueDateClass: 'text-right',
    ganttBarColorClass: 'text-center',
    Wrap: 1,
  }
  columns.forEach((col) => {
    if (col.Name) {
      Header[col.Name] = col.header as string
    }
  })

  Header.Panel = ' '
  return Header
}

const makeLang = ({ firstDayOfWeek, dateSeparator, translations }: MakeLangProps) => {
  return {
    Format: {
      FirstWeekDay: firstDayOfWeek,
      GMT: 1,
      DateSeparator: dateSeparator,
      LongDayNames: translations.longDayNames,
      ShortDayNames: translations.shortDayNames,
      Day2CharNames: translations.day2CharNames,
      Day1CharNames: translations.day1CharNames,
      LongMonthNames: translations.longMonthNames,
      LongMonthNames2: translations.longMonthNames,
      ShortMonthNames: translations.shortMonthNames,
    },
    MenuColumns: {
      Caption: translations.chooseItemsLabel,
      ColsCaption: translations.chooseColumnsLabel,
      SpaceCaption: translations.chooseToolbarsLabel,
      RowsCaption: translations.chooseRowsLabel,
    },
    MenuButtons: {
      Ok: translations.okButtonLabel,
      Cancel: translations.cancelButtonLabel,
      HideAll: translations.hideAllButtonLabel,
      All: translations.allOnButtonLabel,
      Clear: translations.clear,
    },
    MenuPrint: {
      Caption: translations.printOptionsLabel,
      PrintOptions: translations.printOptionsLabel,
      PrintRows: translations.printMaxRowsPerPageLabel,
      PrintPageRoot: translations.printStartRowLabel,
      PrintPageSize: translations.printPageSizeLabel,
      PrintPageOptions: translations.printBrowserPrintInfoLabel,
      PrintPageOrientation: translations.printPageOrientationLabel,
      PrintPageOrientations: `${translations.portraitLabel},${translations.landscapeLabel}`,
      PrintMarginWidth: translations.printMarginWidthLabel,
      PrintMarginHeight: translations.printMarginHeightLabel,
      PDFFitPage: translations.printFitToPageLabel,
      PDFFitPages: `${translations.noneLabel},${translations.widthLabel},${translations.heightLabel},${translations.singlePageLabel}`,
      PrintDPI: translations.printDPIRatioLabel,
      PrintWidth: translations.printPageWidthLabel,
      PrintHeight: translations.printPageHeightLabel,
      PrintResults: translations.printSizeInfo,
    },
    MenuExport: {
      Caption: translations.exportOptionsLabel,
      ExportOptions: translations.exportOptionsLabel,
      ExportName: translations.exportFileNameLabel,
      ExportFormat: translations.exportFileFormatLabel,
      ExportExpanded: translations.exportAllRowsExpandedLabel,
      ExportOutline: translations.exportTreeInExcelLabel,
      ExportIndent: translations.exportIndentTreeInMainColumnLabel,
      ExportFixedRows: translations.exportFreezeHeadRowsLabel,
      ExportFixedCols: translations.exportFreezeLeftColumnsLabel,
      ExportEmpty: translations.exportEmptyCellsLabel,
    },
    MenuFilter: {
      F0: translations.filterOffLabel,
      F1: translations.filterEqualLabel,
      F2: translations.filterNotEqualLabel,
      F3: translations.filterLessThanLabel,
      F4: translations.filterLessThanOrEqualLabel,
      F5: translations.filterGreaterThanLabel,
      F6: translations.filterGreaterThanOrEqualLabel,
      F7: translations.filterBeginsWithLabel,
      F8: translations.filterDoesnotBeginWithLabel,
      F9: translations.filterEndsWithLabel,
      F10: translations.filterDoesnotEndWithLabel,
      F11: translations.filterContainsLabel,
      F12: translations.filterDoesnotContainLabel,
    },
  }
}

const makePanel = ({ translations }: { translations: TreeGridTranslations }) => {
  return {
    PanelDeleteTip: translations.deleteRow,
  }
}

const makeToolbar = ({ isFilterRowVisible, isAddTodoButtonVisible, translations }: MakeToolbarProps) => {
  /**
   * @link https://www.treegrid.com/Doc/GlobalSettings.htm#DefaultToolbar
   */
  return {
    AddChild: 0,
    Cfg: 0,
    Contrasts: 0,
    Debug: 0,
    DefSort: 0,
    GanttStyles: 0,
    Help: 0,
    Indent: 0,
    Lock: 0,
    Outdent: 0,
    Reload: 0,
    Sizes: 0,
    Space: 0,
    Styles: 0,
    WinScroll: 0,
    Correct: 0,
    Add: 0,
    Link: 0,

    Cells05AddTodo: 'AddTodo',
    AddTodoType: 'Button',
    AddTodoButton: 'Button',
    AddTodo: isAddTodoButtonVisible
      ? `<button>${translations.addTodoButtonLabel}<span class="icon"> + </span></button>`
      : 0,
    AddTodoClass: 'toolbarCustomButton toolbarCustomButton--primary',
    AddTodoOnClick: 'window.Grids.OnAddTodo(Grid)',

    Cells10Search: 'Search,Summary',
    SearchLabel: translations.toolbarSearchInputLabel,
    SearchTip: translations.toolbarSearchInputTip,
    SearchType: 'Text',
    SearchCanEdit: 1,
    SearchWidth: 200,
    SearchOnClick: 'Focus AND StartEdit',
    SearchOnChange: 'window.Grids.OnSearchChange(Grid, Value)',

    SummaryType: 'Html',
    SummaryCanEdit: 0,
    Summary: translations.toolbarTodoListSummaryText,

    Cells60Filter: 'Filter',
    FilterType: 'Button',
    FilterButtonClass: isFilterRowVisible ? 'Filter_Button_Active' : 'Filter_Button',
    FilterOnClick: 'window.Grids.OnFilterToggle()',

    Cells50GanttZoom: '', // To turn off the GanttZoom icons on Cells 50
    PrintTip: translations.toolbarPrintTip,
    ExportTip: translations.toolbarExportTip,
    ExpandAllTip: translations.toolbarExpandAllTip,
    CollapseAllTip: translations.toolbarCollapseAllTip,
    ColumnsTip: translations.toolbarColumnsTip,
    FilterTip: translations.toolbarFilterTip,
    ScalesLabel: translations.scaleLabel,
  }
}

const makeActions = () => {
  return {
    OnIns: 'window.Grids.OnAddTodo(Grid)',
  }
}

type MakeToolbarProps = {
  isAddTodoButtonVisible: boolean
  isFilterRowVisible: boolean
  translations: TreeGridTranslations
}

type MakeHeadProps = {
  dateFormat: string
  gridInfo: string
  isFilterRowVisible: boolean
  translations: TreeGridTranslations
}

type MakeHeaderProps = {
  columns: TreeGridColumn[]
  translations: TreeGridTranslations
}

type MakeLangProps = {
  firstDayOfWeek: number
  dateSeparator: string
  translations: TreeGridTranslations
}

type MakeLayoutProps = {
  id: string
  dateFormat: string
  dateSeparator: string
  firstDayOfWeek: number
  gridInfo: string
  isFilterRowVisible: boolean
  isAddTodoButtonVisible: boolean
  defaultVisibleColumns: TodoColumnName[]
  defaultColumnOrder: TodoColumnName[]
  options: Option[]
  translations: TreeGridTranslations
}
