import { SystemStatus, type StatusMap, type SystemStatusType } from './system-status'
import { StatusOptionsFactory } from './status-options'

export class CustomStatusHandler {
  private _status: SystemStatusType
  private _subStatusId: string | null
  private _customStatuses: Partial<StatusMap> = {}
  private _statusOptions: StatusOption[]

  constructor(statusData: StatusData) {
    this._customStatuses = statusData.customStatuses || {}
    this._status = statusData.status
    this._subStatusId = statusData.subStatusId || null
    this._statusOptions = StatusOptionsFactory.create(this._customStatuses)
  }

  get statusOptions(): StatusOption[] {
    return this._statusOptions
  }

  getStatusIndex(): 0 | 1 | 2 | 3 {
    switch (this._status) {
      case SystemStatus.rejected:
        return 3
      case SystemStatus.completed:
        return 2
      case SystemStatus.inProgress:
        return 1
      default:
        return 0
    }
  }

  getSelectedStatus(): string {
    const selectedStatus = this._statusOptions.find((option) => {
      if (this._subStatusId) {
        return option.subStatusId === this._subStatusId
      }
      return option.value === this._status
    })

    return selectedStatus?.label || this._status
  }

  getVisibleOptions(): StatusOption[] {
    return this._statusOptions.filter((option) => option.isVisible)
  }
}

type StatusData = {
  status: SystemStatusType
  subStatusId?: string | null
  customStatuses?: Partial<StatusMap>
}

type StatusOption = ReturnType<(typeof StatusOptionsFactory)['create']>[number]
