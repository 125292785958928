import React from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import OrgGeneralSettings from '../../../../organisations/components/OrgGeneralSettings'
import OrgNotificationSettings from '../../../../organisations/components/OrgNotificationSettings'
import OrgProjectSettingsLayout from './projects'
import { makeStyles, Theme } from '@material-ui/core/styles'
import { useAppContext } from '../../../../hooks/use-app-context'
import { useI18n } from '../../../../hooks'
import { Grid, Box, Typography, CircularProgress } from '@material-ui/core'
import { useAppDispatch } from '../../../../store'
import { FetchOrganisation } from '../../../../organisations/store/actions'
import { FetchOptions } from '../../../../options/store/actions'
import { FetchOrgMemberships } from '../../../../memberships/store'
import { unwrapResult } from '@reduxjs/toolkit'
import { useAsync } from '../../../../hooks/use-async'
import { Link, Route, Switch, useRouteMatch } from 'react-router-dom'
import { paths } from '../../../../paths'
import { useUrlWithContext } from '../../../../hooks/use-url-with-context'

const useLoader = (orgId: string) => {
  const dispatch = useAppDispatch()
  const loader = React.useCallback(async () => {
    dispatch({ type: `load_org_home_route` })
    const results = await Promise.all([
      dispatch(FetchOrganisation(orgId)),
      dispatch(FetchOptions(orgId)),
      dispatch(FetchOrgMemberships(orgId)),
    ])
    results.forEach(unwrapResult)
  }, [dispatch, orgId])
  return useAsync<void, any>(loader)
}

const OrgSettingsLayout = () => {
  const classes = useStyles()
  const { appContext } = useAppContext()
  const { mainContext } = appContext
  const translations = useTranslations()
  const orgId = mainContext?.id as string
  const { createPathWithContext } = useUrlWithContext()
  const { isSuccess, isPending } = useLoader(orgId)
  const isNotifications = useRouteMatch(paths.orgNotificationSettings())
  const isProjects = useRouteMatch(paths.orgProjectSettings())
  const initialTab = isProjects ? 2 : isNotifications ? 1 : 0
  const [currentTab, setCurrentTab] = React.useState(initialTab)

  if (isPending()) return <CircularProgress />
  if (!mainContext || mainContext.type !== 'org' || !isSuccess()) return null

  const generalSettingsPath = createPathWithContext(paths.orgGeneralSettings())
  const notificationSettingsPath = createPathWithContext(paths.orgNotificationSettings())
  const projectSettingsPath = createPathWithContext(paths.orgProjectSettings())

  return (
    <div className={classes.container}>
      <Grid item style={{ marginBottom: 32 }}>
        <Typography variant="h5" data-test="page-header" style={{ fontWeight: 500 }}>
          <Box color="text.secondary" component="span">
            {mainContext.name}
          </Box>
          {' - '}
          {translations.settings}
        </Typography>
      </Grid>
      <Tabs value={currentTab} onChange={(e, v) => setCurrentTab(v)} indicatorColor="primary" className={classes.tabs}>
        <Tab
          component={Link}
          to={generalSettingsPath}
          className={classes.tab}
          disableRipple
          label={translations.general}
        />
        <Tab
          component={Link}
          to={notificationSettingsPath}
          className={classes.tab}
          disableRipple
          label={translations.notifications}
        />
        <Tab
          component={Link}
          to={projectSettingsPath}
          className={classes.tab}
          disableRipple
          label={translations.projectsLabel}
        />
      </Tabs>
      <Switch>
        <Route path={paths.orgProjectSettings()} component={OrgProjectSettingsLayout} />
        <Route path={paths.orgNotificationSettings()} component={OrgNotificationSettings} />
        <Route path={paths.orgGeneralSettings()} component={OrgGeneralSettings} />
      </Switch>
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    maxWidth: 800,
    margin: 'auto',
    paddingTop: 8,
    paddingBottom: 32,
    borderRadius: 8,
  },
  tabs: { borderBottom: `1px solid ${theme.palette.divider}` },
  tab: { minWidth: 120 },
  tabPanel: { padding: theme.spacing(3, 0) },
}))

const defaultTranslations = {
  settings: 'Settings',
  general: 'General',
  notifications: 'Notifications',
  projectsLabel: 'Projects',
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations = {} } = useI18n('translation')

  return {
    settings: translate('settings'),
    general: translate('general'),
    notifications: translate('notifications'),
    projectsLabel: translations.appNavBar?.projectsLabel || defaults.projectsLabel,
  }

  function translate(key: keyof Translations) {
    return translations[key] || defaults[key]
  }
}

type Translations = typeof defaultTranslations

export default OrgSettingsLayout
