import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { RootState } from './store'

const tagTypes = [
  'Board',
  'CalendarEvent',
  'Project',
  'ProjectPermission',
  'Task',
  'TaskPermission',
  'Todo',
  'User',
  'Organisation',
  'Reminder',
  'Link',
  'ProjectType',
] as const
export type TagType = (typeof tagTypes)[number]

export const api = createApi({
  reducerPath: 'api',
  tagTypes,
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState
      const accessToken = state.auth.accessToken
      if (accessToken) {
        headers.set('authorization', `Bearer ${accessToken}`)
      }
      return headers
    },
    responseHandler: async (response: Response) => {
      const contentType = response.headers.get('content-type')
      if (!contentType || response.status === 204) return null
      const shouldParseAsJson = contentType && contentType.includes('application/json')
      const shouldParseAsText = contentType && contentType.includes('text/')

      if (shouldParseAsJson) {
        try {
          const json = await response.json()
          if (!json.success) {
            const errorMessage =
              'error' in json ? json.error : 'message' in json ? json.message : 'Failed to parse the response JSON'
            throw new Error(errorMessage)
          }
          return json.data
        } catch (error) {
          const errorMessage = error instanceof Error ? error.message : 'Failed to parse the response JSON'
          throw new Error(errorMessage)
        }
      }
      if (shouldParseAsText && response.ok) {
        return response.text()
      } else {
        throw new Error('Failed to parse the response text')
      }
    },
  }),
  endpoints: () => ({}),
})
