import { TreeGridTranslations } from '../hooks/use-translations'

export function makeColumns({ translations }: MakeColumnProps): Column[] {
  const openColumn = {
    Name: 'open',
    Type: 'Icon',
    Width: 32,
    CanExport: 0,
    CanPrint: 0,
    CanSort: 0,
    CanHide: 0,
    header: '',
    Class: 'openButton',
  }

  const projectNumberColumn = {
    Name: 'projectNumber',
    MinWidth: 80,
    MaxWidth: 80,
    CaseSensitive: 0,
    header: translations.rowNumberColumnHeader,
  }

  const titleColumn = {
    Name: 'title',
    MinWidth: 160,
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.templateTitleColumnHeader,
  }

  const createdAtColumn = {
    Name: 'createdAt',
    Type: 'Date',
    header: translations.createdAtColumnHeader,
    Width: 154,
  }

  const creatorNameColumn = {
    Name: 'creatorName',
    MinWidth: 120,
    RelWidth: 1,
    CaseSensitive: 0,
    header: translations.creatorColumnHeader,
  }

  const panelColumn = { Name: 'Panel', Visible: 0, CanHide: 0 }

  return [openColumn, projectNumberColumn, titleColumn, createdAtColumn, creatorNameColumn, panelColumn]
}

type MakeColumnProps = {
  translations: TreeGridTranslations
}

type Column = Partial<TCol> & {
  header?: string
}
