import React from 'react'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import Stack from './Stack'
import { ArrowUp, ArrowDown, Trash2, Plus, Eye, EyeOff } from 'react-feather'
import { makeStyles, Theme } from '@material-ui/core'
import { COLOR_BLACK, COLOR_GRAY } from '../constants'
import { useConfirmDialog } from './ConfirmDialog'
import { useI18n } from '../hooks'
import { SystemStatusId } from '../organisations/api/project-type-response'

const systemStatusTranslationKeyByStatusId = {
  '0': 'notStarted',
  '1': 'inProgress',
  '2': 'completed',
  '3': 'rejected',
} as const

const SubStatusList = ({
  mainStatusId,
  subStatuses,
  updateSubStatus,
  deleteSubStatus,
}: {
  mainStatusId: SystemStatusId
  subStatuses: SubStatus[]
  updateSubStatus: UpdateSubStatusFn
  deleteSubStatus: DeleteSubStatusFn
}) => {
  const classes = useStyles()
  const translations = useTranslations()
  const [editedSubStatus, setEditedSubStatus] = React.useState<EditedSubStatus | null>(null)
  const { confirm: confirmDeleteSubStatus } = useConfirmDialog({
    onConfirm: deleteSubStatus,
    title: translations.deleteStatus,
    text: translations.deleteStatusConfirmation,
  })

  const handleMoveUp = (subStatus: SubStatus) => {
    if (subStatus.order === 0) return
    const updateData = { order: subStatus.order - 1 }
    updateSubStatus(subStatus.id, updateData)
  }

  const handleMoveDown = (subStatus: SubStatus) => {
    if (subStatus.order === subStatuses.length - 1) return
    const updateData = { order: subStatus.order + 1 }
    updateSubStatus(subStatus.id, updateData)
  }

  const showTextField = (id: string) => {
    const subStatus = subStatuses.find((subStatus) => subStatus.id === id)
    if (!subStatus) return
    setEditedSubStatus(subStatus)
  }

  const handleRename = () => {
    const prevName = subStatuses.find((subStatus) => subStatus.id === editedSubStatus?.id)?.name
    const newName = editedSubStatus && editedSubStatus.name
    const shouldUpdateName = newName && prevName !== newName

    if (shouldUpdateName) updateSubStatus(editedSubStatus.id, { name: editedSubStatus.name })
    setEditedSubStatus(null)
  }

  const handleBlurTextField = (event: React.FocusEvent<HTMLInputElement>) => {
    handleRename()
  }

  const handleChangeTextField = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value
    setEditedSubStatus((prev) => (prev ? { ...prev, name } : prev))
  }

  const handleKeyDownTextField = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') return handleRename()
    if (event.key === 'Escape') return setEditedSubStatus(null)
  }

  const handleUpdateVisibility = (id: string, isVisible: boolean) => {
    updateSubStatus(id, { isVisibleInOptions: isVisible })
  }

  if (!subStatuses.length) return null
  const translatedMainStatus = translations[systemStatusTranslationKeyByStatusId[mainStatusId]]

  return (
    <Box sx={{ mt: 3, pt: 2 }}>
      <Typography variant="subtitle1" style={{ fontWeight: 500 }} gutterBottom>
        {translations.additionalStatuses.replace('{{statusName}}', translatedMainStatus)}
      </Typography>
      <Box className={classes.listContainer}>
        <List disablePadding>
          {subStatuses.map((subStatus, index) => {
            const isFirst = index === 0
            const isLast = index === subStatuses.length - 1
            const visibilityTooltip = subStatus.isVisibleInOptions
              ? translations.hideInStatusOptions
              : translations.showInStatusOptions

            return (
              <ListItem key={subStatus.id} divider={!isLast}>
                {editedSubStatus?.id === subStatus.id ? (
                  <TextField
                    value={editedSubStatus.name}
                    onChange={handleChangeTextField}
                    onKeyDown={handleKeyDownTextField}
                    onBlur={handleBlurTextField}
                    autoFocus
                  />
                ) : (
                  <ListItemText
                    primary={subStatus.name}
                    secondary={subStatus.isVisibleInOptions ? undefined : translations.isHiddenInStatusOptions}
                    primaryTypographyProps={{
                      onClick: () => showTextField(subStatus.id),
                      style: { cursor: 'pointer' },
                    }}
                  />
                )}
                <ListItemSecondaryAction className={classes.actions}>
                  {isFirst ? null : (
                    <Tooltip title={translations.moveUp} placement="top">
                      <IconButton size="small" edge="end" disabled={isFirst} onClick={() => handleMoveUp(subStatus)}>
                        <ArrowUp size={16} color={isFirst ? COLOR_GRAY : COLOR_BLACK} />
                      </IconButton>
                    </Tooltip>
                  )}
                  {isLast ? null : (
                    <Tooltip title={translations.moveDown} placement="top">
                      <IconButton size="small" edge="end" disabled={isLast} onClick={() => handleMoveDown(subStatus)}>
                        <ArrowDown size={16} color={isLast ? COLOR_GRAY : COLOR_BLACK} />
                      </IconButton>
                    </Tooltip>
                  )}
                  <Tooltip title={visibilityTooltip} placement="top">
                    <IconButton
                      size="small"
                      edge="end"
                      onClick={() => handleUpdateVisibility(subStatus.id, !subStatus.isVisibleInOptions)}
                    >
                      {subStatus.isVisibleInOptions ? (
                        <Eye size={16} color={COLOR_BLACK} />
                      ) : (
                        <EyeOff size={16} color={COLOR_BLACK} />
                      )}
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={translations.deleteStatus} placement="top">
                    <IconButton size="small" edge="end" onClick={() => confirmDeleteSubStatus(subStatus.id)}>
                      <Trash2 size={16} color={COLOR_BLACK} />
                    </IconButton>
                  </Tooltip>
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </Box>
    </Box>
  )
}

const NewSubStatusFormDialog = ({ open, closeDialog, addSubStatus }: NewSubStatusFormDialogProps) => {
  const translations = useTranslations()
  const [name, setName] = React.useState('')

  const handleAddSubStatus = () => {
    closeDialog()
    addSubStatus(name)
    setName('')
  }

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') return handleAddSubStatus()
  }

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth PaperProps={{ style: { padding: 16 } }}>
      <DialogTitle>{translations.addNewStatus}</DialogTitle>
      <DialogContent>
        <TextField
          onChange={(event) => setName(event.target.value)}
          value={name}
          onKeyDown={handleKeyDown}
          label={translations.statusName}
          placeholder={translations.enterStatusName}
          autoFocus
          fullWidth
        />
      </DialogContent>
      <DialogActions style={{ padding: '24px 24px 0 24px' }}>
        <Button onClick={closeDialog} size="small">
          {translations.cancel}
        </Button>
        <Button onClick={handleAddSubStatus} variant="contained" color="primary" size="small">
          {translations.add}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

const SubStatuses = ({
  mainStatusId,
  subStatuses,
  addSubStatus,
  updateSubStatus,
  deleteSubStatus,
}: SubStatusesProps) => {
  const translations = useTranslations()
  const [isDialogOpen, setIsDialogOpen] = React.useState(false)

  const openDialog = () => setIsDialogOpen(true)
  const closeDialog = () => setIsDialogOpen(false)
  const handleClickAddSubStatus = (event: React.MouseEvent<HTMLButtonElement>) => {
    openDialog()
    event.currentTarget.blur()
  }

  return (
    <Stack>
      <SubStatusList
        mainStatusId={mainStatusId}
        subStatuses={subStatuses}
        updateSubStatus={updateSubStatus}
        deleteSubStatus={deleteSubStatus}
      />
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickAddSubStatus}
        endIcon={<Plus size={16} />}
        size="small"
      >
        {translations.addNewStatus}
      </Button>
      <NewSubStatusFormDialog open={isDialogOpen} closeDialog={closeDialog} addSubStatus={addSubStatus} />
    </Stack>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  listContainer: {
    marginTop: 16,
    padding: 0,
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    '& .MuiListItem-container:hover $actions': {
      opacity: 1,
    },
  },
  actions: {
    display: 'flex',
    gap: theme.spacing(1),
    opacity: 0,
    transition: theme.transitions.create(['opacity']),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  emptyMessageContainer: {
    padding: 24,
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: 4,
    textAlign: 'center',
  },
}))

const defaultTranslations = {
  additionalStatuses: 'Additional statuses corresponding to {{statusName}}',
  addNewStatus: 'Add new status',
  statusName: 'Status name',
  enterStatusName: 'Enter status name here',
  cancel: 'Cancel',
  add: 'Add',
  moveUp: 'Move up',
  moveDown: 'Move down',
  showInStatusOptions: 'Show in status options',
  hideInStatusOptions: 'Hide in status options',
  isHiddenInStatusOptions: 'This status is currently hidden in status options',
  deleteStatus: 'Delete status',
  deleteStatusConfirmation: 'Are you sure you want to delete this status?',
  notStarted: 'Not started',
  inProgress: 'In progress',
  completed: 'Completed',
  rejected: 'Rejected',
}
const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n('translation')
  const { translations: projectTranslations } = useI18n('project')
  const statusMenuTranslations = projectTranslations.projectStatusMenu

  return {
    ...defaults,
    ...statusMenuTranslations,
    ...translations,
  }
}

export default SubStatuses

type AddSubStatusFn = (name: string) => void
type UpdateSubStatusFn = (id: string, data: SubStatusUpdateData) => void
type DeleteSubStatusFn = (id: string) => void
type SubStatus = { id: string; name: string; order: number; isVisibleInOptions: boolean }
type SubStatusesProps = {
  mainStatusId: SystemStatusId
  subStatuses: SubStatus[]
  addSubStatus: AddSubStatusFn
  updateSubStatus: UpdateSubStatusFn
  deleteSubStatus: DeleteSubStatusFn
}
type NewSubStatusFormDialogProps = { open: boolean; closeDialog: () => void; addSubStatus: AddSubStatusFn }
type SubStatusUpdateData = { name?: string; order?: number; isVisibleInOptions?: boolean }
type EditedSubStatus = { id: string; name: string }
type Translations = typeof defaultTranslations
