import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import ActivitiesList from '../treegrid/activities-list'
import { CircularProgress, Theme, makeStyles } from '@material-ui/core'
import { useUserTimeZone } from '../hooks/use-user-time-zone'
import { useFirstDayOfWeek } from '../hooks/use-first-day-of-week'
import { useDateFormat } from '../users/hooks/use-date-format'
import { useI18n } from '../hooks'
import { useAppContext } from '../hooks/use-app-context'
import {
  getCalendarEventQueryFromContext,
  getProjectQueryFromContext,
  getTaskQueryFromContext,
  getTodoQueryFromContext,
} from '../context-options/utils'
import { useGetCalendarEventsQuery } from '../calendar-events/api'
import { ProjectsQueryParams, useGetProjectsQuery } from '../projects/api'
import { TodoQueryParams, useGetTodosQuery } from '../todos/api'
import { TasksQueryParams, useGetTasksQuery } from '../tasks/api'
import { useAllOptions } from '../options/hooks/use-all-options'
import { getOptionsForMainContext } from '../options/utils'
import { Status, StringMap } from '../types/common'
import { AppMainContextType, AppSubContextType } from '../types/app-context'
import ErrorList from '../components/ErrorList'
import { useWeekendDays } from '../hooks/use-weekend-days'
import { PROJECT_TYPE } from '../projects/constants'

const queryOptions = { refetchOnMountOrArgChange: true } as const

const useLoader = () => {
  const translations = useTranslations()
  const queryErrors = {} as QueryErrors
  const {
    appContext: { mainContext, subContext },
  } = useAppContext()
  const context = subContext || mainContext

  const todoQuery = makeTodosQuery(context)
  const taskQuery = makeTasksQuery(context)
  const calendarEventQuery = getCalendarEventQueryFromContext(context)
  const projectQuery = makeProjectsQuery(context)

  const {
    data: calendarEvents = [],
    isLoading: isLoadingCalendarEvents,
    isError: isErrorCalendarEvents,
    status: calendarEventStatus,
  } = useGetCalendarEventsQuery(calendarEventQuery, queryOptions)
  const {
    data: projects = [],
    isLoading: isLoadingProjects,
    isError: isErrorProjects,
    status: projectStatus,
  } = useGetProjectsQuery(projectQuery, queryOptions)
  const {
    data: todos = [],
    isLoading: isLoadingTodos,
    isError: isErrorTodos,
    status: todoStatus,
  } = useGetTodosQuery(todoQuery, queryOptions)
  const {
    data: tasks = [],
    isLoading: isLoadingTasks,
    isError: isErrorTasks,
    status: taskStatus,
  } = useGetTasksQuery(taskQuery, queryOptions)

  const isLoading = isLoadingCalendarEvents || isLoadingProjects || isLoadingTodos || isLoadingTasks
  const isError = isErrorCalendarEvents || isErrorProjects || isErrorTodos || isErrorTasks

  if (isErrorProjects) queryErrors.project = translations.fetchErrorProjects
  if (isErrorTasks) queryErrors.task = translations.fetchErrorTasks
  if (isErrorTodos) queryErrors.todo = translations.fetchErrorTodos
  if (isErrorCalendarEvents) queryErrors.calendarEvent = translations.fetchErrorCalendarEvents

  const isFulfilled =
    calendarEventStatus === 'fulfilled' &&
    projectStatus === 'fulfilled' &&
    todoStatus === 'fulfilled' &&
    taskStatus === 'fulfilled'

  return {
    isLoading,
    isError,
    isFulfilled,
    context,
    mainContext,
    calendarEvents,
    projects,
    todos,
    tasks,
    queryErrors,
  }
}

const HomeView = () => {
  const classes = useStyles()
  const translations = useTranslations()
  const userTimeZone = useUserTimeZone()
  const firstDayOfWeek = useFirstDayOfWeek()
  const weekendDays = useWeekendDays()
  const { dateFormat, dateSeparator } = useDateFormat()
  const { isLoading, isError, isFulfilled, context, mainContext, calendarEvents, projects, todos, tasks, queryErrors } =
    useLoader()
  const { options } = useAllOptions()
  const [key, setKey] = React.useState(context?.id)

  React.useEffect(() => {
    if (isFulfilled) {
      setKey(context?.id)
    }
  }, [isFulfilled, context?.id])

  if (isLoading) return <CircularProgress />
  if (!context || !mainContext) return <ErrorList errors={[translations.contextNotFoundError]} />
  if (isError) return <ErrorList errors={queryErrors} />

  return (
    <>
      <div className={classes.headerContainer}>
        <Typography variant="h6">
          <Box component="span" color="text.secondary">
            {`${context.name} - `}
          </Box>
          <Box component="span"> {translations.pageTitleLabel} </Box>
        </Typography>
      </div>
      <ActivitiesList
        key={key}
        calendarEvents={calendarEvents}
        projects={projects}
        todos={todos}
        tasks={tasks}
        dateFormat={dateFormat}
        dateSeparator={dateSeparator}
        firstDayOfWeek={firstDayOfWeek}
        gridInfo={`${context.name} - ${translations.pageTitleLabel}`}
        timeZone={userTimeZone}
        options={getOptionsForMainContext(options, mainContext)}
        weekendDays={weekendDays}
      />
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  headerContainer: {
    display: 'flex',
    margin: theme.spacing(0.75, 0, 1.125, 0),
    '& > :first-child': {
      flexGrow: 1,
      marginRight: 32,
    },
  },
}))

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('translation')
  const { translations: mt } = useI18n('membership')

  const translations = t || ({} as StringMap)
  const membershipTranslations = mt?.membershipHomeView || ({} as StringMap)

  const {
    contextNotFoundError = defaults.contextNotFoundError,
    fetchErrorProjects = defaults.fetchErrorProjects,
    fetchErrorTasks = defaults.fetchErrorTasks,
    fetchErrorTodos = defaults.fetchErrorTodos,
    fetchErrorCalendarEvents = defaults.fetchErrorCalendarEvents,
  } = translations
  const { pageTitleLabel = defaults.pageTitleLabel, showFiltersButtonLabel = defaults.showFiltersButtonLabel } =
    membershipTranslations

  return {
    pageTitleLabel,
    showFiltersButtonLabel,
    contextNotFoundError,
    fetchErrorCalendarEvents,
    fetchErrorProjects,
    fetchErrorTasks,
    fetchErrorTodos,
  }
}

const defaultTranslations = {
  pageTitleLabel: 'All current and future activities',
  showFiltersButtonLabel: 'Filters',
  contextNotFoundError: 'Context not found',
  fetchErrorCalendarEvents: 'Failed to fetch calendar events',
  fetchErrorProjects: 'Failed to fetch projects',
  fetchErrorTasks: 'Failed to fetch tasks',
  fetchErrorTodos: 'Failed to fetch todos',
}

const makeProjectsQuery = (context: AppMainContextType | AppSubContextType | null): ProjectsQueryParams => {
  return {
    links: 'true',
    type: PROJECT_TYPE.NORMAL,
    archived: 'false',
    status: [Status.NOT_STARTED, Status.IN_PROGRESS],
    ...getProjectQueryFromContext(context),
  }
}

const makeTasksQuery = (context: AppMainContextType | AppSubContextType | null): TasksQueryParams => {
  return {
    links: 'true',
    archived: 'false',
    status: [Status.NOT_STARTED, Status.IN_PROGRESS],
    ...getTaskQueryFromContext(context),
  }
}

const makeTodosQuery = (context: AppMainContextType | AppSubContextType | null): TodoQueryParams => {
  return {
    completed: 'false',
    ...getTodoQueryFromContext(context),
  }
}

type Translations = typeof defaultTranslations
type QueryErrors = {
  calendarEvent?: string
  project?: string
  task?: string
  todo?: string
  organisation?: string
}

export default HomeView
