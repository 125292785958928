import { buildUrl } from '../../utils/url'

// eslint-disable-next-line
export const makeProjectApiEndpoints = (projectId = ':projectId') => {
  const endpoints = {
    projects: (params?: URLSearchParams) => buildUrl('/projects', params),
    projectItem: (id = '') => `/projects/${id || projectId}`,
    projectNumber: (id = '') => `${endpoints.projectItem(id)}/project-number`,
    projectCustomers: (id = '') => `${endpoints.projectItem(id)}/customers`,
    projectManagers: (id = '') => `${endpoints.projectItem(id)}/managers`,
    projectParticipants: (id = '') => `${endpoints.projectItem(id)}/participants`,
    projectSpectators: (id = '') => `${endpoints.projectItem(id)}/spectators`,
    projectSuppliers: (id = '') => `${endpoints.projectItem(id)}/suppliers`,
    projectWorkspaces: (id = '') => `${endpoints.projectItem(id)}/workspaces`,
    projectPlan: (id = '') => `${endpoints.projectItem(id)}/plan`,
    projectStatus: (id = '') => `${endpoints.projectItem(id)}/status`,
    projectStatusDescription: (id = '') => `${endpoints.projectItem(id)}/status-description`,
    projectPermissions: (id = '') => `${endpoints.projectItem(id)}/permissions`,
    projectBasePlan: (id = '') => `${endpoints.projectItem(id)}/base-plan`,
    projectTimeComponent: (id = '') => `${endpoints.projectItem(id)}/time-component`,
    projectCustomerItem: (customerId = ':customerId', projectId = '') =>
      `${endpoints.projectCustomers(projectId)}/${customerId}`,
    projectManagerItem: (managerId = ':managerId', projectId = '') =>
      `${endpoints.projectManagers(projectId)}/${managerId}`,
    projectParticipantItem: (participantId = ':participantId', projectId = '') =>
      `${endpoints.projectParticipants(projectId)}/${participantId}`,
    projectSpectatorItem: (spectatorId = ':spectatorId', projectId = '') =>
      `${endpoints.projectSpectators(projectId)}/${spectatorId}`,
    projectSupplierItem: (supplierId = ':supplierId', projectId = '') =>
      `${endpoints.projectSuppliers(projectId)}/${supplierId}`,
    projectWorkspaceItem: (workspaceId = ':workspaceId', projectId = '') =>
      `${endpoints.projectWorkspaces(projectId)}/${workspaceId}`,
    userProjects: (userId = ':userId') => `/users/${userId}/projects`,
    orgProjects: (orgId = ':orgId') => `/orgs/${orgId}/projects`,
    orgProjectPermissions: (orgId = ':orgId') => `${endpoints.orgProjects(orgId)}/permissions`,
    archiveProject: (projectId = ':projectId') => `${endpoints.projectItem(projectId)}/archive`,
    unarchiveProject: (projectId = ':projectId') => `${endpoints.projectItem(projectId)}/unarchive`,
    projectNotificationSettings: (projectId = ':projectId') =>
      `${endpoints.projectItem(projectId)}/notification-settings`,
    copyProject: () => `/projects/copy`,
  }
  return endpoints
}
