import React from 'react'
import Fade from '@material-ui/core/Fade'
import IconButton from '@material-ui/core/IconButton'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import { Check, ChevronDown } from 'react-feather'
import { makeStyles, Theme } from '@material-ui/core'
import { COLOR_BLACK, COLOR_LIGHT } from '../../constants'
import { useI18n } from '../../hooks'
import { useUpdateProjectStatus } from '../hooks/use-update-project-status'
import { ProjectViewModel } from '../api/project'
import { Status } from '../../types/common'

const ProjectStatusWidget = ({ label, project }: ProjectStatusWidgetProps) => {
  const { updateProjectStatus: updateStatus } = useUpdateProjectStatus()
  const classes = useStyles()
  const translations = useTranslations()

  const translationsByStatus = {
    [Status.NOT_STARTED]: translations.notStarted,
    [Status.IN_PROGRESS]: translations.inProgress,
    [Status.COMPLETED]: translations.completed,
    [Status.REJECTED]: translations.rejected,
  }
  const translatedStatus = translationsByStatus[project.customStatus as Status] || project.customStatus
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLDivElement>(null)
  const openMenu = (e: React.MouseEvent<HTMLDivElement>) => setAnchorEl(e.currentTarget)
  const closeMenu = () => setAnchorEl(null)

  const menuItems = project.statusOptions.map((option) => {
    const onClick = () => updateStatus(project.id, { status: option.value, subStatusId: option.subStatusId || null })
    const translatedLabel = translationsByStatus[option.label as Status] || option.label
    const isCurrentStatus = project.subStatusId
      ? project.customStatus === option.label
      : option.subStatusId
      ? false
      : project.status === option.value

    return {
      isCurrentStatus,
      label: translatedLabel,
      value: option.value,
      onClick,
    }
  })

  if (!project.canUpdateStatus) {
    return (
      <>
        <Typography variant="body1" style={{ marginBottom: 8 }}>
          <strong>{label}</strong>
        </Typography>
        <Typography variant="body1">{translatedStatus}</Typography>
      </>
    )
  }

  return (
    <>
      <Typography variant="body1" style={{ marginBottom: 4 }}>
        <strong>{label}</strong>
      </Typography>
      <div className={classes.button} role="button" onClick={openMenu}>
        <Typography variant="body1">{translatedStatus}</Typography>
        <IconButton className="arrowDownIcon">
          <ChevronDown size={16} color={COLOR_BLACK} />
        </IconButton>
      </div>
      <Menu
        id="status-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        PaperProps={{ style: { width: 280 } }}
        disablePortal
        keepMounted
      >
        {menuItems.map((menuItem) => {
          return (
            <MenuItem onClick={menuItem.onClick} key={menuItem.label}>
              <ListItemIcon>
                {menuItem.isCurrentStatus && (
                  <Fade in>
                    <Check size={18} color={COLOR_BLACK} />
                  </Fade>
                )}
              </ListItemIcon>
              <ListItemText primary={menuItem.label} />
            </MenuItem>
          )
        })}
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 12,
    height: theme.spacing(5),
    margin: theme.spacing(0, -1),
    paddingLeft: theme.spacing(),
    borderRadius: theme.spacing(),
    cursor: 'pointer',
    transition: theme.transitions.create('background'),
    '&:hover': {
      background: COLOR_LIGHT,
      '& .arrowDownIcon': {
        opacity: 1,
      },
    },
    '& .arrowDownIcon': {
      opacity: 0,
      transition: theme.transitions.create('opacity'),
    },
  },
}))

type ProjectStatusWidgetProps = {
  label: string
  project: ProjectViewModel
}

const useTranslations = (defaults: Translations = defaultTranslations): Translations => {
  const { translations: t } = useI18n('project')
  const statusMenuTranslations = t.projectStatusMenu || {}
  const {
    notStarted = defaults.notStarted,
    inProgress = defaults.inProgress,
    completed = defaults.completed,
    rejected = defaults.rejected,
  } = statusMenuTranslations

  return {
    notStarted,
    inProgress,
    completed,
    rejected,
  }
}

const defaultTranslations = {
  notStarted: 'Not started',
  inProgress: 'In progress',
  completed: 'Completed',
  rejected: 'Rejected',
}
type Translations = typeof defaultTranslations

export default ProjectStatusWidget
