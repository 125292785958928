import React from 'react'
import { Option } from '../types'
import { makeTreeGridProjectRow, makeTreegridProjectRows } from './rows'
import { TimeZoneType } from '../../constants/timezones'
import { ProjectColumnName, TreeGridProject } from './types'
import { makeTreegridLayout } from './layout'
import {
  createTreegrid,
  destroyTreegrid,
  getSummaryTextWithRowCount,
  syncDataFromServerToGrid,
  updateGridData,
} from '../utils/tree-grid'
import { useTreeGridFilters } from '../hooks/use-treegrid-filters'
import {
  getColorFromColorHtml,
  getShouldRemoveProjectRowBasedOnContext,
  getStateFromStateIcon,
  mapGroupByToColumns,
  mapTranslationsToColor,
  mapTranslationsToGroupBy,
} from '../utils'
import { DateTimeService } from '../../services/date-time-service'
import { useRouter } from '../../hooks'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { useTranslations } from '../hooks/use-translations'
import { ProjectsViewOptions } from '../../projects/components/ProjectsViewSelect'
import { useUpdateProjectStatus } from '../../projects/hooks/use-update-project-status'
import { useAppContext } from '../../hooks/use-app-context'
import { useContextOptions } from '../../context-options/hooks/use-context-options'
import { useAuthUserMembership } from '../../memberships/hooks/use-auth-user-membership'
import { paths } from '../../paths'
import { useProjectMutations } from '../../projects/hooks/use-project-mutations'
import { ProjectViewModel } from '../../projects/api/project'
import { addDays, addMonths, subDays } from 'date-fns'
import { getMaxDate, getMinDate } from '../../utils'
import { GroupBy } from '../../types/common'
import { SystemStatus } from '../../lib/system-status'

const id = '__treegrid_project_list__'

export const useProjectList = (props: ProjectListProps) => {
  const translations = useTranslations()
  const { initProjectUpdate } = useProjectMutations()
  const { history, ...router } = useRouter()
  const [navigateTo, setNavigateTo] = React.useState<string | null>(null)
  const { createPathWithContext } = useUrlWithContext()
  const { appContext } = useAppContext()
  const { userMembershipContextOptions } = useContextOptions()
  const { showFilters, toggleFilters } = useTreeGridFilters({ gridId: id })
  const { updateProjectStatus, updatedProject } = useUpdateProjectStatus()
  const authUserMembership = useAuthUserMembership()
  const zoomRef = React.useRef<string | null>(null)
  const canCreateProjects = Boolean(appContext.mainContext?.type === 'user' || authUserMembership?.canCreateProjects)
  const query = router.query as { view?: ProjectsViewOptions }
  const projectsView = query.view || 'currentAndFuture'

  const toolbarSummaryText = getSummaryTextWithRowCount(
    translations.toolbarProjectListSummaryText,
    props.projects.length
  )
  translations.toolbarProjectListSummaryText = toolbarSummaryText

  React.useEffect(() => {
    if (navigateTo) history.push(createPathWithContext(navigateTo))
    return () => setNavigateTo(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigateTo])

  React.useEffect(() => {
    const grid = window.Grids[id]
    if (grid && updatedProject) {
      const shouldRemoveRow = getShouldRemoveRow(updatedProject, projectsView)
      if (shouldRemoveRow) {
        const row = grid.GetRowById(updatedProject.id)
        if (row) grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
      } else {
        const projectRow = makeTreeGridProjectRow({ project: updatedProject, translations, ...props })
        syncDataFromServerToGrid(grid, [projectRow])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatedProject])

  const showGantt = projectsView === 'currentAndFuture' || projectsView === 'all'
  const showGroupBy = appContext.mainContext?.type === 'org'
  const layout = makeTreegridLayout({
    id,
    showFilters,
    translations,
    canCreateProjects,
    showGantt,
    showGanttToolbarActions: showGantt,
    groupBy: GroupBy.NO_GROUPING,
    showGroupBy,
    ...props,
  })

  const allPlannedStartDates = props.projects.map((project) => project.plannedStartDate).filter(Boolean)
  const allPlannedEndDates = props.projects.map((project) => project.plannedEndDate).filter(Boolean)
  const allActualStartDates = props.projects.map((project) => project.actualStartDate).filter(Boolean)
  const allActualEndDates = props.projects.map((project) => project.actualEndDate).filter(Boolean)
  const allDates = allPlannedStartDates.concat(allPlannedEndDates).concat(allActualStartDates).concat(allActualEndDates)
  const minStartDate = getMinDate(allDates)
  const maxEndDate = getMaxDate(allDates)
  const dateTimeService = new DateTimeService({
    dateFormat: props.dateFormat,
    timeZone: props.timeZone,
  })

  // @ts-ignore
  window.Grids['OnFilterToggle'] = function () {
    toggleFilters()
  }

  window.Grids.OnValueChanged = function (
    grid: TGrid,
    row: TRow & TreeGridProject,
    column: ProjectColumnName,
    newValue,
    oldValue
  ) {
    // NOTE: Returning early from this function will cause unexpected behavior in the treegrid
    const isColumnEditAllowed = (row as any)[`${column}CanEdit`] === 1
    const hasValueChanged = newValue !== oldValue
    const shouldDispatchUpdateRequest = isColumnEditAllowed && hasValueChanged
    if (shouldDispatchUpdateRequest) {
      let value = adaptValue({ column, newValue, oldValue })

      // If the column is a date column, then we need to adjust the timezone
      if (isDateColumn(column) && value) {
        const dateTimeService = new DateTimeService({
          dateFormat: props.dateFormat,
          timeZone: props.timeZone,
          enableTimeComponent: row.enableTimeComponent,
        })
        const date = new Date(value)
        const adjustedDate = dateTimeService.addTimezoneOffset(date, 'UTC')
        value = adjustedDate.toISOString()
      }

      if (column === 'ganttBarColor') {
        row.GanttGanttClass = value === 'Default' ? 'Blue' : value // changes the color of the gantt bar immediately
        value = mapTranslationsToColor(translations)[value]
      }

      if (column === 'status') {
        const [mainStatusId, subStatusId] = newValue.toString().split('_')
        const status = SystemStatus.statusByIndex[parseInt(mainStatusId)]
        updateProjectStatus(row.id, { status, subStatusId: subStatusId ?? null })
      }

      if (column === 'actualStartDate' || column === 'actualEndDate') {
        updateProjectStatus(row.id, { [column]: value })
        return
      }

      initProjectUpdate(row.id, { field: column as any, value }).then((projectResponse) => {
        if (!projectResponse || !grid) return
        const project = 'project' in projectResponse ? projectResponse.project : projectResponse
        const projectRow = makeTreeGridProjectRow({ project, translations, ...props })
        const shouldRemoveRow = getShouldRemoveProjectRowBasedOnContext(
          project,
          appContext,
          userMembershipContextOptions
        )
        if (shouldRemoveRow) {
          grid.AnimateRow(row, 'Delete', undefined, () => grid.RemoveRow(row))
        } else {
          syncDataFromServerToGrid(grid, [projectRow])
        }
      })
    }
    return newValue
  }

  window.Grids.OnClick = function (grid, row, col) {
    if (col === 'open') {
      const isProjectRow = row.Def.Name !== 'Group'
      if (isProjectRow) setNavigateTo(paths.projectBasic(row.id))
    }
    if (col === 'AddProject') setNavigateTo(paths.newProject())
    if (col === 'ZoomFit' && grid) {
      const zoomToStart = dateTimeService.removeTimezoneOffset(minStartDate || new Date(), 'UTC')
      const zoomToEnd = dateTimeService.removeTimezoneOffset(
        maxEndDate ? addDays(maxEndDate, 1) : addMonths(new Date(), 1),
        'UTC'
      )
      grid.ZoomTo(zoomToStart, zoomToEnd)
      return true
    }
  }

  // @ts-ignore
  window.Grids.OnSearchChange = function (grid: TGrid, value: string) {
    if (grid) {
      grid.SearchExpression = value
      grid.DoSearch('Filter')
    }
  }

  window.Grids.OnFilterFinish = function (grid: TGrid) {
    if (grid) {
      const rowCount = grid.GetShownRows().length
      const toolbarSummaryText = getSummaryTextWithRowCount(translations.toolbarProjectListSummaryText, rowCount)
      // @ts-expect-error
      grid.Toolbar.Summary = toolbarSummaryText
      grid.RefreshRow(grid.Toolbar)
    }
  }

  window.Grids.OnGetSortValue = function (
    grid: TGrid,
    row: TRow & TreeGridProject,
    column: ProjectColumnName,
    value: any
  ) {
    if (column === 'plannedEndDate' || column === 'plannedStartDate') {
      const shouldSortToBottom = !value
      if (shouldSortToBottom) return Number.MAX_SAFE_INTEGER
    }
    return value
  }

  window.Grids.OnRenderFinish = function (grid) {
    if (grid) {
      const yesterday = subDays(new Date(), 1)
      const oneMonthAhead = addMonths(new Date(), 1)
      const zoomToStart = dateTimeService.removeTimezoneOffset(yesterday, 'UTC')
      const zoomToEnd = dateTimeService.removeTimezoneOffset(
        maxEndDate && maxEndDate > oneMonthAhead ? addDays(maxEndDate, 1) : oneMonthAhead,
        'UTC'
      )
      grid.ZoomTo(zoomToStart, zoomToEnd)
    }
  }

  window.Grids.OnZoom = function (_, zoom) {
    zoomRef.current = zoom
  }

  // @ts-ignore
  window.Grids.OnSelectGroupByChange = function (grid: TGrid, groupBy: string) {
    if (grid) {
      const groubByValue = mapTranslationsToGroupBy(translations)[groupBy] as GroupBy
      const Group = mapGroupByToColumns(groubByValue)
      grid.DoGrouping(Group)
    }
  }

  return { layout, projectsView }
}

const ProjectList = (props: ProjectListProps) => {
  const translations = useTranslations()
  const { layout, projectsView } = useProjectList(props)
  const { projects, dateFormat, timeZone, options } = props

  React.useEffect(() => {
    const data = makeTreegridProjectRows({ projects, translations, dateFormat, options, timeZone })
    createTreegrid({ id, layout, data })
    return () => destroyTreegrid(id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.defaultVisibleColumns, props.defaultColumnOrder])

  React.useEffect(() => {
    const data = makeTreegridProjectRows({ projects, translations, dateFormat, options, timeZone })
    updateGridData({ id, data })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectsView])

  return <div id={id} style={{ height: 'calc(100vh - 128px)' }} data-test="project-list-container"></div>
}

export default ProjectList

function adaptValue({ column, newValue, oldValue }: { column: string; newValue: any; oldValue: any }) {
  let adaptedValue: any = newValue
  if (isDateColumn(column)) {
    if (newValue) adaptedValue = new Date(newValue).toISOString()
    else adaptedValue = ''
  }
  if (
    column === 'participants' ||
    column === 'managers' ||
    column === 'suppliers' ||
    column === 'workspaces' ||
    column === 'customers'
  ) {
    const oldArray: string[] = oldValue.split(';').filter(Boolean)
    const newArray: string[] = newValue.split(';').filter(Boolean)
    const add = newArray.filter((x) => !oldArray.includes(x))
    const remove = oldArray.filter((x) => !newArray.includes(x))
    adaptedValue = { add, remove }
  }
  if (column === 'state') {
    adaptedValue = getStateFromStateIcon(newValue)
  }
  if (column === 'ganttBarColor') {
    adaptedValue = getColorFromColorHtml(newValue)
  }
  return adaptedValue
}

function isDateColumn(columnName: string) {
  return (
    columnName === 'actualEndDate' ||
    columnName === 'actualStartDate' ||
    columnName === 'plannedEndDate' ||
    columnName === 'plannedStartDate'
  )
}

function getShouldRemoveRow(project: ProjectViewModel, projectsView: ProjectsViewOptions) {
  if (projectsView === 'all') return false
  if (projectsView === 'templates') return false
  if (project.archived) return projectsView !== 'archived'
  if (project.isRejected) return projectsView !== 'rejected'
  if (project.isCompleted) return projectsView !== 'completed'
  if (project.isNotStarted || project.isInProgress) return projectsView !== 'currentAndFuture'
  return false
}

type ProjectListProps = {
  defaultColumnOrder: ProjectColumnName[]
  defaultVisibleColumns: ProjectColumnName[]
  projects: ProjectViewModel[]
  options: Option[]
  gridInfo: string
  dateFormat: string
  dateSeparator: string
  firstDayOfWeek: DayOfWeek
  timeZone: TimeZoneType
  weekendDays: DayOfWeek[]
}
