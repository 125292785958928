import React from 'react'
import { useTranslation } from 'react-i18next'

export const useDocumentLanguage = () => {
  const { i18n } = useTranslation()

  React.useEffect(() => {
    if (window) {
      window.document.documentElement.lang = i18n.language
    }
  }, [i18n.language])
}
