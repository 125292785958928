import * as organisationApi from '../api'
import { useSnackbar } from 'notistack'
import { useUpdateOrgNotificationSettingsMutation } from '../api'
import { NotificationChannel, NotificationType } from '../../notifications/notifications'
import { SystemStatusId } from '../api/project-type-response'

export const useOrgMutations = () => {
  const { enqueueSnackbar } = useSnackbar()
  const [updateNotificationSettingsMutation] = useUpdateOrgNotificationSettingsMutation()
  const [addProjectTypeMutation] = organisationApi.useAddProjectTypeMutation()
  const [updateProjectTypeMutation] = organisationApi.useUpdateProjectTypeMutation()
  const [deleteProjectTypeMutation] = organisationApi.useDeleteProjectTypeMutation()
  const [renameProjectStatusMutation] = organisationApi.useRenameProjectStatusMutation()
  const [addSubStatusMutation] = organisationApi.useAddSubStatusMutation()
  const [updateSubStatusMutation] = organisationApi.useUpdateSubStatusMutation()
  const [deleteSubStatusMutation] = organisationApi.useDeleteSubStatusMutation()

  const handleError = (error: any) => {
    const errorMessage = 'error' in error ? error.error : 'message' in error ? error.message : 'Unknown error'
    enqueueSnackbar(errorMessage, { variant: 'error' })
  }

  const enableNotification = (orgId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [{ type, channel }], disable: [] }
    return updateNotificationSettings(orgId, updateData)
  }

  const disableNotification = (orgId: string, type: NotificationType, channel: NotificationChannel) => {
    const updateData = { enable: [], disable: [{ type, channel }] }
    return updateNotificationSettings(orgId, updateData)
  }

  const updateNotificationSettings = async (orgId: string, data: organisationApi.OrgNotificationSettingsUpdateData) => {
    return await updateNotificationSettingsMutation({ orgId, ...data })
      .unwrap()
      .catch(handleError)
  }

  const addProjectType = async (orgId: string, type: string) => {
    return await addProjectTypeMutation({ orgId, type }).unwrap().catch(handleError)
  }

  const updateProjectType = async (
    orgId: string,
    projectTypeId: string,
    updateData: organisationApi.ProjectTypeUpdateData
  ) => {
    return await updateProjectTypeMutation({ orgId, id: projectTypeId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const deleteProjectType = async (orgId: string, projectTypeId: string) => {
    return await deleteProjectTypeMutation({ orgId, id: projectTypeId }).unwrap().catch(handleError)
  }

  const renameProjectStatus = async (
    id: SystemStatusId,
    projectTypeId: string,
    orgId: string,
    updateData: organisationApi.ProjectCustomStatusUpdateData
  ) => {
    return await renameProjectStatusMutation({ orgId, id, projectTypeId, ...updateData })
      .unwrap()
      .catch(handleError)
  }

  const addSubStatus = async (
    projectStatusId: SystemStatusId,
    projectTypeId: string,
    orgId: string,
    subStatusData: organisationApi.NewSubStatusData
  ) => {
    return await addSubStatusMutation({ projectStatusId, projectTypeId, orgId, ...subStatusData })
      .unwrap()
      .catch(handleError)
  }

  const updateSubStatus = async (
    id: string,
    projectTypeId: string,
    projectStatusId: SystemStatusId,
    orgId: string,
    subStatusData: organisationApi.SubStatusUpdateData
  ) => {
    return await updateSubStatusMutation({ id, orgId, projectTypeId, projectStatusId, ...subStatusData })
      .unwrap()
      .catch(handleError)
  }

  const deleteSubStatus = async (id: string, projectTypeId: string, projectStatusId: SystemStatusId, orgId: string) => {
    return await deleteSubStatusMutation({ id, orgId, projectTypeId, projectStatusId }).unwrap().catch(handleError)
  }

  return {
    enableNotification,
    disableNotification,
    updateNotificationSettings,
    addProjectType,
    updateProjectType,
    deleteProjectType,
    renameProjectStatus,
    addSubStatus,
    updateSubStatus,
    deleteSubStatus,
  }
}
