import React from 'react'
import Alert from '@material-ui/lab/Alert'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import ProjectForm, { ProjectFormData } from '../../projects/components/ProjectForm'
import { CircularProgress, makeStyles, Theme } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useI18n } from '../../hooks'
import { StringMap } from '../../types/common'
import { useAppContext } from '../../hooks/use-app-context'
import { useAuthUser } from '../../users/hooks/use-auth-user'
import { useUrlWithContext } from '../../hooks/use-url-with-context'
import { useProjectMutations } from '../../projects/hooks/use-project-mutations'
import { useGetOrganisationByIdQuery } from '../../organisations/api'
import { paths } from '../../paths'
import { CopyProjectData, NewProjectData, useGetProjectsQuery } from '../../projects/api'
import { getProjectQueryFromContext } from '../../context-options/utils'
import { PROJECT_TYPE } from '../../projects/constants'

const NewProjectRoute = () => {
  const classes = useStyles()
  const history = useHistory()
  const translations = useTranslations(defaultTranslations)
  const { createPathWithContext } = useUrlWithContext()
  const { appContext } = useAppContext()
  const { mainContext, subContext } = appContext
  const { user, dateFormat } = useAuthUser()
  const { createUserProject, createOrgProject, copyProject } = useProjectMutations()
  const [projectId, setProjectId] = React.useState<string | null>(null)
  const currentCtx = subContext || mainContext
  const organisationId = mainContext?.type === 'org' ? mainContext.id : ''
  const { data: organisation, isLoading } = useGetOrganisationByIdQuery(organisationId, { skip: !organisationId })
  const datePickerCalendarStartDay = organisation?.firstDayOfWeek || user?.firstDayOfWeek || 0
  const projectQuery = { type: PROJECT_TYPE.TEMPLATE, ...getProjectQueryFromContext(mainContext) }
  const { data: projects } = useGetProjectsQuery(projectQuery)

  const handleCreateProject = async (projectData: ProjectFormData) => {
    const { copyFromProjectId } = projectData
    if (copyFromProjectId) await onCopyProject(projectData as CopyProjectData)
    else await onCreateProject(projectData)
  }

  const onCreateProject = async (projectData: NewProjectData) => {
    if (currentCtx?.type === 'user' && user) {
      const createdProject = await createUserProject(user.id, projectData)
      if (createdProject) setProjectId(createdProject.id)
    }

    if (currentCtx && currentCtx?.type !== 'user') {
      const orgId = 'parentContextId' in currentCtx ? currentCtx.parentContextId : currentCtx.id
      const newProjectData: NewProjectData = { ...projectData }
      if (currentCtx.type === 'customer') newProjectData.customers = [currentCtx.id]
      if (currentCtx.type === 'supplier') newProjectData.suppliers = [currentCtx.id]
      if (currentCtx.type === 'workspace') newProjectData.workspaces = [currentCtx.id]
      if (currentCtx.type === 'member') newProjectData.managers = [currentCtx.id]
      const createdProject = await createOrgProject(orgId, newProjectData)
      if (createdProject) setProjectId(createdProject.id)
    }
  }

  const onCopyProject = async (projectData: CopyProjectData) => {
    if (currentCtx?.type === 'user' && user) {
      const createdProject = await copyProject(projectData)
      if (createdProject) setProjectId(createdProject.id)
    }

    if (currentCtx && currentCtx?.type !== 'user') {
      const newProjectData: CopyProjectData = { ...projectData }
      if (currentCtx.type === 'customer') newProjectData.customers = [currentCtx.id]
      if (currentCtx.type === 'supplier') newProjectData.suppliers = [currentCtx.id]
      if (currentCtx.type === 'workspace') newProjectData.workspaces = [currentCtx.id]
      if (currentCtx.type === 'member') newProjectData.managers = [currentCtx.id]
      const createdProject = await copyProject(projectData)
      if (createdProject) setProjectId(createdProject.id)
    }
  }

  React.useEffect(() => {
    if (projectId) {
      const path = createPathWithContext(paths.projectBasic(projectId))
      history.push(path)
    }
  }, [projectId, history, createPathWithContext])

  if (isLoading) return <CircularProgress />

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Paper className={classes.container} elevation={0} data-test="new-project-card">
          <header className={classes.header}>
            <Typography variant="h4" component="h4">
              {translations.pageTitle}
            </Typography>
            {mainContext?.type === 'org' && subContext && (
              <Alert severity="info">
                <strong>{subContext.name}</strong>{' '}
                {translations.subContextInfo.replace('{{subContextType}}', translations[subContext.type])}
              </Alert>
            )}
          </header>
          <main>
            <ProjectForm
              onSubmit={handleCreateProject}
              dateFormat={dateFormat}
              datePickerCalendarStartDay={datePickerCalendarStartDay}
              projects={projects}
            />
            {mainContext?.type === 'org' && (
              <Typography variant="caption" component="p" color="textSecondary" className={classes.contextHint}>
                {translations.contextHint}&nbsp;
                <strong style={{ textDecoration: 'underline' }}>{mainContext.name}</strong>
              </Typography>
            )}
          </main>
        </Paper>
      </Grid>
    </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(5),
    border: `1px solid ${theme.palette.divider}`,
  },
  header: {
    paddingBottom: theme.spacing(4),
    '& h4': {
      paddingBottom: theme.spacing(2),
    },
  },
  contextHint: {
    marginTop: theme.spacing(),
  },
}))

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations: t } = useI18n('project')
  const translations = (t?.newProjectPage || {}) as StringMap
  const {
    pageTitle = defaults.pageTitle,
    subContextInfo = defaults.subContextInfo,
    customer = defaults.customer,
    supplier = defaults.supplier,
    member = defaults.member,
    workspace = defaults.workspace,
    contextHint = defaults.contextHint,
  } = translations

  return {
    pageTitle,
    subContextInfo,
    customer,
    supplier,
    member,
    workspace,
    contextHint,
  }
}

const defaultTranslations = {
  pageTitle: 'Create a new project',
  subContextInfo: 'will be added as {{subContextType}} in this project',
  customer: 'customer',
  supplier: 'supplier',
  member: 'manager',
  workspace: 'workspace',
  contextHint: 'Project will be created in the organisation',
}

export type Translations = typeof defaultTranslations

export default NewProjectRoute
