import { TreeGridTranslations } from '../hooks/use-translations'
import { makeColumns } from './columns'

export const makeTreegridLayout = (props: CreateTreegridLayoutProps) => {
  const Cfg = makeCfg(props)
  const Cols = makeColumns(props)
  const Head = makeHead(props)
  const Header = makeHeader({ columns: Cols, ...props })
  const Lang = makeLang(props)
  const Toolbar = makeToolbar(props)
  const Actions = makeActions()
  const Solid = [{ id: 'NoData', Html: props.translations.noData }]

  return {
    Cfg,
    LeftCols: Cols,
    Head,
    Lang,
    Toolbar,
    Actions,
    Solid,
    Header,
  }
}

const makeCfg = ({ id }: { id: string }) => {
  /** @link link for documentation: https://www.treegrid.com/Doc/SearchAttr.html */
  return {
    id,
    SuppressCfg: process.env.NODE_ENV === 'development' ? 1 : 0,
    CfgId: id,
    Style: 'white', // Base treegrid style
    GanttStyle: 'Material', // Base Gantt style
    Selecting: 0, // disables selecting of rows
    LeftCanResize: 4,
    RightCanResize: 4,
    PrintVisible: 0,
    PrintExpanded: 1,
    PrintPageOrientation: 1,
    PrintPaddingHeight: 20,
    PDFFitPage: 1,
    Code: process.env.REACT_APP_TREEGRID_CODE,
    WordWrap: 0,
  }
}

const makeHead = ({ translations }: MakeHeadProps) => {
  const headerRow = {
    id: 'headerRow',
    Space: -1,
    Visible: 0,
    PanelVisible: 0,
    CanExport: 0,
    CanPrint: 0,
    Cells: `Logo,GridInfo,PrintDate`,
    LogoRelWidth: 1,
    LogoAligh: 'Left',
    LogoVAligh: 'middle',
    LogoType: 'Html',
    Logo: `<img style="height: 40px;" src="/favicon.png"  alt="company logo"/>`,
  }
  const filtersRow = {
    CanExport: 0,
    CanPrint: 0,
    Visible: 0,
  }

  return [headerRow, filtersRow]
}

const makeHeader = ({ columns, translations }: MakeHeaderProps) => {
  const Header: Record<string, string | number> = {
    Name: translations.headerRowLabel,
    createdAtClass: 'text-right',
    Wrap: 1,
    Height: 80,
  }
  columns.forEach((col) => {
    if (col.Name) {
      Header[col.Name] = col.header as string
      Header[`${col.Name}Wrap`] = 1
    }
  })
  return Header
}

const makeLang = ({ translations }: MakeLangProps) => {
  return {
    Format: {
      GMT: 1,
      LongDayNames: translations.longDayNames,
      ShortDayNames: translations.shortDayNames,
      Day2CharNames: translations.day2CharNames,
      Day1CharNames: translations.day1CharNames,
      LongMonthNames: translations.longMonthNames,
      LongMonthNames2: translations.longMonthNames,
      ShortMonthNames: translations.shortMonthNames,
    },
    MenuColumns: {
      Caption: translations.chooseItemsLabel,
      ColsCaption: translations.chooseColumnsLabel,
      SpaceCaption: translations.chooseToolbarsLabel,
      RowsCaption: translations.chooseRowsLabel,
    },
    MenuButtons: {
      Ok: translations.okButtonLabel,
      Cancel: translations.cancelButtonLabel,
      HideAll: translations.hideAllButtonLabel,
      All: translations.allOnButtonLabel,
      Clear: translations.clear,
    },
    MenuPrint: {
      Caption: translations.printOptionsLabel,
      PrintOptions: translations.printOptionsLabel,
      PrintRows: translations.printMaxRowsPerPageLabel,
      PrintPageRoot: translations.printStartRowLabel,
      PrintPageSize: translations.printPageSizeLabel,
      PrintPageOptions: translations.printBrowserPrintInfoLabel,
      PrintPageOrientation: translations.printPageOrientationLabel,
      PrintPageOrientations: `${translations.portraitLabel},${translations.landscapeLabel}`,
      PrintMarginWidth: translations.printMarginWidthLabel,
      PrintMarginHeight: translations.printMarginHeightLabel,
      PDFFitPage: translations.printFitToPageLabel,
      PDFFitPages: `${translations.noneLabel},${translations.widthLabel},${translations.heightLabel},${translations.singlePageLabel}`,
      PrintDPI: translations.printDPIRatioLabel,
      PrintWidth: translations.printPageWidthLabel,
      PrintHeight: translations.printPageHeightLabel,
      PrintResults: translations.printSizeInfo,
    },
    MenuExport: {
      Caption: translations.exportOptionsLabel,
      ExportOptions: translations.exportOptionsLabel,
      ExportName: translations.exportFileNameLabel,
      ExportFormat: translations.exportFileFormatLabel,
      ExportExpanded: translations.exportAllRowsExpandedLabel,
      ExportOutline: translations.exportTreeInExcelLabel,
      ExportIndent: translations.exportIndentTreeInMainColumnLabel,
      ExportFixedRows: translations.exportFreezeHeadRowsLabel,
      ExportFixedCols: translations.exportFreezeLeftColumnsLabel,
      ExportEmpty: translations.exportEmptyCellsLabel,
    },
    MenuFilter: {
      F0: translations.filterOffLabel,
      F1: translations.filterEqualLabel,
      F2: translations.filterNotEqualLabel,
      F3: translations.filterLessThanLabel,
      F4: translations.filterLessThanOrEqualLabel,
      F5: translations.filterGreaterThanLabel,
      F6: translations.filterGreaterThanOrEqualLabel,
      F7: translations.filterBeginsWithLabel,
      F8: translations.filterDoesnotBeginWithLabel,
      F9: translations.filterEndsWithLabel,
      F10: translations.filterDoesnotEndWithLabel,
      F11: translations.filterContainsLabel,
      F12: translations.filterDoesnotContainLabel,
    },
  }
}

const makeToolbar = ({ canCreateProjects, translations }: MakeToolbarProps) => {
  /**
   * @link https://www.treegrid.com/Doc/GlobalSettings.htm#DefaultToolbar
   */
  let toolbar: any = {
    AddChild: 0,
    Cfg: 0,
    Contrasts: 0,
    Debug: 0,
    DefSort: 0,
    GanttStyles: 0,
    Help: 0,
    Indent: 0,
    Lock: 0,
    Outdent: 0,
    Reload: 0,
    Sizes: 0,
    Space: 0,
    Styles: 0,
    WinScroll: 0,
    Correct: 0,
    Add: 0,
    Link: 0,
    Print: 0,
    Export: 0,
    Columns: 0,
    Height: 40,

    Cells10Search: 'AddProject',

    AddProjectType: 'Button',
    AddProjectButton: 'Button',
    AddProject: canCreateProjects
      ? `<button>${translations.addProjectButtonLabel}<span class="icon"> + </span></button>`
      : 0,
    AddProjectClass: 'toolbarCustomButton toolbarCustomButton--primary',

    ColumnsTip: translations.toolbarColumnsTip,
    ScalesLabel: translations.scaleLabel,
  }

  return toolbar
}

const makeActions = () => {
  return {
    OnIns: '', // This is for resetting the default behavior of treegrid, which is to AddRow on insert key press
    OnDel: '', // This is for resetting the default behavior of treegrid, which is to DeleteRow on delete key press
  }
}

type MakeToolbarProps = {
  canCreateProjects: boolean
  translations: TreeGridTranslations
}

type MakeHeadProps = {
  translations: TreeGridTranslations
}

type CreateTreegridLayoutProps = {
  id: string
  translations: TreeGridTranslations
  canCreateProjects: boolean
}

type MakeLangProps = {
  translations: TreeGridTranslations
}

type MakeHeaderProps = {
  columns: { header?: string; Name?: string }[]
  translations: TreeGridTranslations
}
