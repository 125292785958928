import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import TextField from '@material-ui/core/TextField'
import SubStatuses from '../../components/SubStatuses'
import Typography from '@material-ui/core/Typography'
import { debounce } from 'lodash'
import { useAppContext } from '../../hooks/use-app-context'
import { SystemStatusId } from '../api/project-type-response'
import { useOrgMutations } from '../hooks/use-organisation-mutations'
import { ProjectTypeViewModel } from '../api/project-type'
import { makeStyles, Theme } from '@material-ui/core'
import { useI18n } from '../../hooks'

const systemStatusTranslationKeyByStatusId = {
  '0': 'notStarted',
  '1': 'inProgress',
  '2': 'completed',
  '3': 'rejected',
} as const

const ProjectCustomStatus = ({ status, projectTypeId }: { status: ProjectCustomStatusType; projectTypeId: string }) => {
  const classes = useStyles()
  const translations = useTranslations()
  const mutations = useOrgMutations()
  const orgId = useAppContext().appContext.mainContext?.id as string

  const createDebouncedRenameProjectStatus = (id: SystemStatusId) => {
    return debounce((event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const name = event.target.value
      mutations.renameProjectStatus(id, projectTypeId, orgId, { name })
    }, 300)
  }

  const syncStatusNameInputWithBackend = (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const inputField = event.target
    if (inputField.value !== status.name && !inputField.value) {
      inputField.value = status.name
    }
  }

  const addSubStatus = (name: string) => {
    mutations.addSubStatus(status.systemStatusId, projectTypeId, orgId, { name })
  }

  const updateSubStatus = (id: string, updateData: { name?: string; order?: number }) => {
    mutations.updateSubStatus(id, projectTypeId, status.systemStatusId, orgId, updateData)
  }

  const deleteSubStatus = (id: string) => {
    mutations.deleteSubStatus(id, projectTypeId, status.systemStatusId, orgId)
  }

  return (
    <Paper variant="outlined" className={classes.paper}>
      <Grid container>
        <Grid item xs={4}>
          <Typography variant="subtitle1">
            {translations[systemStatusTranslationKeyByStatusId[status.systemStatusId]]}
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <Box>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              {translations.renameStatus}
            </Typography>
            <TextField
              type="text"
              defaultValue={defaultTranslations[systemStatusTranslationKeyByStatusId[status.systemStatusId]]}
              onChange={createDebouncedRenameProjectStatus(status.systemStatusId)}
              onBlur={syncStatusNameInputWithBackend}
              variant="outlined"
              size="small"
              fullWidth
            />
          </Box>
          <SubStatuses
            mainStatusId={status.systemStatusId}
            subStatuses={status.subStatuses}
            addSubStatus={addSubStatus}
            updateSubStatus={updateSubStatus}
            deleteSubStatus={deleteSubStatus}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

const ProjectCustomStatuses = ({ projectType }: ProjectCustomStatusesProps) => {
  const projectCustomStatusList = Object.values(projectType.projectCustomStatuses)
  const { projectStatuses, projectStatusDescription } = useTranslations()

  return (
    <div style={{ margin: '24px auto' }}>
      <Typography variant="h6" gutterBottom>
        {projectStatuses}
      </Typography>
      <Typography variant="body2" color="textSecondary" style={{ marginBottom: 16 }}>
        {projectStatusDescription}
      </Typography>
      {projectCustomStatusList.map((status) => (
        <ProjectCustomStatus key={status.systemStatusId} status={status} projectTypeId={projectType.id} />
      ))}
    </div>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    padding: theme.spacing(2, 1),
    border: 0,
    borderBottom: `1px solid ${theme.palette.divider}`,
    borderRadius: 0,
    '&:nth-child(2)': {
      borderTop: `1px solid ${theme.palette.divider}`,
    },
  },
}))

const defaultTranslations = {
  projectStatuses: 'Project statuses',
  projectStatusDescription:
    'You can change the name of the statuses and add additional statuses from here. Additional statuses added here will be available in the project status dropdown.',
  renameStatus: 'Rename status',
  notStarted: 'Not started',
  inProgress: 'In progress',
  completed: 'Completed',
  rejected: 'Rejected',
}

const useTranslations = (defaults = defaultTranslations): Translations => {
  const { translations } = useI18n('translation')
  const { translations: projectTranslations } = useI18n('project')
  const statusMenuTranslations = projectTranslations.projectStatusMenu

  return {
    ...defaults,
    ...statusMenuTranslations,
    ...translations,
  }
}

export default ProjectCustomStatuses

type ProjectCustomStatusesProps = { projectType: ProjectTypeViewModel }
type ProjectCustomStatusType = ProjectTypeViewModel['projectCustomStatuses'][0]
type Translations = typeof defaultTranslations
